import logoLink from '../resources/vzug.svg';
import companyLogoLink from '../resources/vzug.svg';
import footerLogoLink from '../resources/vzug_emeye-t-inside_logo.svg';

export const cssVariables = {
    header_footer_seperator: '#003494',
    panel_highlight: '#003494',
    chat_background_right: '#E6EDF8',
    chat_background_left: '#EBEBEB',

    active: '#CCFFCC',
    inactive: '#FFCCCC',
    text_on_active: '#000',
    text_on_inactive: '#000',

    dropdown_background: '#B3C7E7',

    phonebook_dropdown: '#003494',
    phonebook_dropdown_svg: '#fff',

    primary_light: '#E6EBF4',
    primary: '#003494',
    text_on_primary: '#fff',
    text_on_primary_caller: 'rgb(120, 120, 120)',

    secondary: '#211A1D',
    text_on_secondary: 'white',
    focus_active: '#003494',

    red: '#f00',
    green: '#0a0',

    connecting: '#c0c0c0',
    connected: '#00aa00',
    aborted: '#ff0000',

    font_color: 'black',
    link_color: '#666666',

    font: 'Arial, Helvetica, sans-serif',
    font_size: '16px',
    font_weight: '400',

    border_radius: '20px',
    border_width: '2px',
    border_style: 'solid',
    border_radius_narrow: '10px',
    border_radius_super_narrow: '5px',
    border_width_nav: '8px',

    disabled: '#dbdbdb',

    gridbox_header_bg: '#ededed',
    gridbox_header_text: '#003494',
    gridbox_padding: '8px',
    gridbox_header_padding: '8px',

    pointer: '#003494',

    toggle_switch: 'rgb(120, 120, 120)',
};

export const logo = logoLink;
export const companyTitle = 'V-ZUG AG';
export const companyLogo = companyLogoLink;
export const footerLogo = footerLogoLink;
